<template>
  <div>
    <v-card class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">Unohtuiko salasanasi?</v-card-title>
      <v-card-subtitle>
        Syötä sähköpostiosoitteesi niin lähetämme sinulle linkin salasanan uusimiseksi.
      </v-card-subtitle>

      <!-- reset form -->
      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <v-text-field
            v-model="email"
            label="Sähköposti"
            name="email"
            outlined
            @keyup.enter="submit"
          ></v-text-field>

          <v-btn block x-large color="primary" @click="submit">Uusi salasana</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <div class="text-center mt-6">
      <router-link to="/login"> Takaisin kirjautumiseen </router-link>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";

export default {
  title: "Unohtuiko salasana?",
  mixins: [mixins],
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async submit() {
      let email = this.email;
      try {
        await apiAgent({
          method: "post",
          url: "/api/v1/user/forgotPassword",
          data: { email },
        });
        this.showPopup(
          "Antamaasi sähköpostiin on lähetetty linkki salasanan uusimikseksi. Linkki on voimassa 10 min.",
          "success"
        );
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped></style>
